html {
    background-color: white;
    width: 100%;
    height: 100%;
}

.othersSite{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

input:focus {
    outline: none; /* Remove default focus outline */
    border-color: rgb(255, 255, 255); /* Add a border color to indicate focus */
    box-shadow: 0 0 2px rgb(255, 255, 255); /* Add a box shadow to indicate focus */
  }

@font-face {
    font-family: Roboto;
    src: url('../font/Roboto/Roboto-Light.ttf');
}

@font-face{
    font-family: Haas Grot Disp Light;
    src: url('../font/HaasGrotDisp/HaasGrotDispApp-45Light.ttf');
}

@font-face{
    src: url('../font/HaasGrotDisp/HaasGrotDispApp-65Medium.ttf');
    font-family: Haas Grot Disp Roman;
}

@font-face{
    font-family: Haas Grot Disp Medium;
    src: url('../font/HaasGrotDisp/HaasGrotTextApp-55Roman.ttf');
}

@font-face{
    font-family: Art Company Mono;
    src: url('../font/ArtCompanyMono/ArtCompanyMono-Light.otf');
}

.errorContainer{
   height: 25px;
   margin-bottom: 0;
   padding-top: 0;
}

.mainText, .deleteAccountStep{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 105.3%;
    letter-spacing: -0.04em;

    color: #000000;
}
.verificationText {
    font-family: Roboto;
    font-style: normal;
    margin-top: 100px;
    font-weight: 600;
    font-size: 30px;
    line-height: 105.3%;
    letter-spacing: -0.04em;
    color: #000000;
}
.main{
    background-color: white;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

#root{
    width: 100%;
}

.App{
    width: 100%;
    height: 100%;
    /* min-height: 80dvh; */
    overflow: hidden;
    background-color: #FFFFFF;
}

*{
   box-sizing:content-box;  
}

.loginScreenContainer{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

.othersImg{
    padding-top:62px;
    width: 85%;
    height: 65px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.titleText{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    line-height: 105.3%;

    text-align: center;
    letter-spacing: -0.04em;

    color: #000000;
}

.menuText{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 105.3%;

    text-align: center;
    letter-spacing: -0.04em;

    color: #000000;
}

.secondaryText{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 134.3%;
    /* or 19px */
    
    letter-spacing: -0.04em;
    
    color: black;
}

.credentials{
    width:335px;
    height: 190px;
    margin-left: auto;
    margin-right: auto;
}
/* @media only screen and (min-height: 1024px) {
    .credentials{
        padding-top: 50px;
    }
} */

.emailBox input{
    font-family: Roboto;
    font-style: normal;
    font-size: 20px;
    color: #000000;
    padding-left: 10px;
}

.passwordBox input{
    font-family: Roboto;
    font-style: normal;
    font-size: 20px;
    color: #000000;
    padding-left: 10px;
}
.confirmPasswordBox input{
    font-family: Roboto;
    font-style: normal;
    font-size: 20px;
    color: #000000;
    padding-left: 10px;
}


.nameBox input{
    font-family: Roboto;
    font-style: normal;
    font-size: 20px;
    color: #000000;
    padding-left: 10px;
    text-transform:capitalize;
}

.email{
    text-align: left;
    width: 35px;
    height: 16px;
}

.password{
    width: fit-content;
    height: 16px;
    padding-top: 21px;
}

.confirmNewpassword{
    width: fit-content;
    height: 16px;
    padding-top: 21px;
}
.successMessage{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 105.3%;
    padding-top: 70px;
    text-align: center;
    letter-spacing: -0.04em;

    color: #000000;
}
.box{
    box-sizing: border-box;
    width: 335px;
    height: 46px;
    margin-top: 11px;
    left: calc(50% - 335px/2);
    top: calc(50% - 46px/2 - 25px);

    border: 1px solid #000000;
    background-color: #ffffff00;
    border-radius: 9px;
}

.box:focus{
    border: 2px solid #000000;
}

.forgot{
    margin-top: 7px;
    text-align: center;
    background: none;
    border: none;
    cursor: pointer;
}

.submitButton{
    /* position: absolute; */
    width: 143px;
    height: 46px;
    /* bottom:114px; */
    left: calc(50% - 143px/2);
    top: calc(50% - 46px/2 + 269px);
    box-shadow:none ;
    background: #000000;
    border-radius: 9px;
    border-style: solid;
    align-items: center;
    text-align: center;
    color: #ffffff;
    margin-top: 50px;
    cursor: pointer;
}
.proceedButton{
    width: 143px;
    height: 46px;
    left: calc(50% - 143px/2);
    top: calc(50% - 46px/2 + 269px);
    box-shadow:none ;
    background: #000000;
    border-style: solid;
    border-radius: 9px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px; 
    align-items: center;
    text-align: center;
    letter-spacing: -0.04em;
    margin-top: 100px;    
    color: #ffffff;    
}

@media only screen and (min-height: 1080px) {
    .proceedButton{
        margin-top: 200px;
    }
}

.resetPasswordButton{
    /* position: absolute; */
    width: 143px;
    height: 46px;
    /* bottom:114px; */
    left: calc(50% - 143px/2);
    top: calc(50% - 46px/2 + 269px);
    box-shadow:none ;
    background: #000000;
    border-radius: 9px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px; 
    align-items: center;
    text-align: center;
    letter-spacing: -0.04em;
    color: #ffffff;
    margin-top: 130px;
}

/* @media only screen and (min-height: 1080px) {
    .loginButton{
        margin-top: 5%;
    }
} */
/* @media only screen and (min-height: 1080px) {
    .loginButton{
        margin-top: 5%;
    }
} */

.activateHere{
    width: 100%;
    height: auto;
    margin-top: 100px;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}
.bottomInfo{
    /* position: absolute; */
    width: 100%;
    height: 45px;
    margin-top: 20px;
    margin-bottom: 20px;
    /* bottom:10px; */
    align-items: center;
    text-align: center;
}

.signUp{
    position: relative;
    align-items: center;
    text-align: center;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
}

/* Sign Up */

.othersImgSmall{
    width:97.365%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
}


.smallTitle{
    width: fit-content;
    height:fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 25px;
}

.signUpCredentials{
    padding-top: 5px; 
    width:335px;
    margin-left: auto;
    margin-right: auto;
}
/* @media only screen and (min-height: 1080px) {
    .signUpCredentials{
        padding-top: 50px;
    }
} */
nput:-webkit-autofill::first-line {
    font-size: 20px;
}
.name{
   width: fit-content;
}

.code{
    padding-top: 50px; 
    width: fit-content;
}

.email2{
    padding-top: 21px; 
    width: fit-content;
}

.password2{
    padding-top: 21px;
    width: fit-content; 
}

.confirmPassword{
    padding-top: 21px;
    width: fit-content; 
}

.smallGreyFont{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
    text-align: center;
    letter-spacing: -0.005em; 
    color: rgba(0, 0, 0, 0.5);
} 
@media only screen and (min-height: 1080px) {
    .smallGreyFont{
        font-size: 12px;
        line-height: 13px;
    }
}

.terms{
    width:100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
}
@media only screen and (min-height: 1080px) {
    .terms{
        padding-top: 50px;
    }
}

.termsButton{
    position: relative;
    align-items: center;
    text-align: center;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
    text-align: center;
    letter-spacing: -0.005em; 
    color: #000000;
    text-decoration: underline;
}

@media only screen and (min-height: 1080px) {
    .termsButton{
        font-size: 12px;
    }
}

.emailInfo{
    width:auto;
    text-align: center;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.subscription{
    margin-left: auto;
    margin-right: auto;
    width: 87.33vw;
    /* padding-top: 120px; */
}

.subInfo{
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    width: 87.33vw;
}

@media only screen and (min-height: 1180px) {
    .subInfo{
        width: 67.33vw;
    }
    .subscription{
        width: 67.33vw;
    }
}

.redeemScreen{
    width: 100%;
    height: 100%;
}

.redeemCode{
    position: relative;
    align-items: center;
    text-align: center;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: #000000;
    text-decoration: underline;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #000000;
}

.burgerMenu{
    position: fixed;
    align-items: center;
    text-align: center;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    left:3px;
    top:0px;
    z-index: 999;
}
.burgerMenuImg{
    width: 32px;
    height: 32px;
}

.menuButton{
    align-items: center;
    text-align: center;
    background: none;
    border: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    text-align: center;
    padding-top: 5px ;
    padding-bottom: 5px;
}

.menuButtonsContainer{
    width: 100%;
    padding-top: 20vh;
}

/* @media only screen and (min-height: 1024px) {
    .menuButtonsContainer{
        padding-top: 30%;
    }
} */
/* @media only screen and (min-height: 1180px) {
    .menuButtonsContainer{
        padding-top: 300px;
    }
} */

.menuLines{
    border: 0.6px solid #000000;
    width: 89.6vw;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-height: 1024px) {
    .menuLines{
        width: 75vw;
    }
}
@media only screen and (min-height: 1180px) {
    .menuLines{
        width: 600px;
    }
}


.xButton{
    position: fixed;
    align-items: center;
    text-align: center;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    width: 26px;
    height: 26px;
    left:4px;
    top:4px;
}
.xButtonImg{
    width: 26px;
    height: 26px;
}

@media only screen and (max-width: 625px) {

    .xButton{
        width: 18px;
        height: 18px;
    }

    .xButtonImg{
        width: 18px;
        height: 18px;
    }

    .burgerMenu{
        width: 24px;
        height: 24px;
    }

    .burgerMenuImg{
        width: 24px;
        height: 24px;
    }
}

.menuScreen{
    width: 100%;
    height: 100%;
    z-index: 10;
}

.redeemTitle{
    padding-top:80px;
}

form {
    display: flex;
    justify-content: center;
}

.formContainer input{
    margin: 0 0.2rem;
    /* margin-top: 150px; */
    padding: 0.5rem;
    border: 1px solid #000000;
    border-color: #000000;
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 3rem;
    left: calc(50% - 35px/2 - 101px);
    top: calc(50% - 35px/2 - 17.5px);
    border-radius: 9px;
    background-color: #ffffff00;
    font-family: Roboto;
    font-style: normal;
    font-size: 35PX;
    color: #000000;
    text-transform: uppercase;
}
@media only screen and (max-width: 376px) {
    /* .othersImgSmall{
        width: 110.67px;
        height: 22.13px;
    } */
    .formContainer input{
        width: 23.33px;
        height: 23.33px; 
        font-size: 25PX; 
    }
    .titleText{
        font-size: 28px;
    }
    .secondaryText{
        font-size: 13px;
    }
    .menuText{
        font-size: 25px;
    }
    .titleText{
        padding-top: 25px;
    }
    .box{
        height: 40px;
    }
} 
@media only screen and (max-width: 281px) {
    .formContainer input{
        width: 19.33px;
        height: 19.33px;  
    }
    .menuText{
        font-size: 20px;
    }
    .signUpCredentials{
        width:260px;
    }
    .credentials{
        width:260px;
    }
    .box{
        width:260px;
        height: 36px;
    }
} 

@media only screen and (max-height: 670px){
    /* .subscription{
        padding-top: 60px;
    } */
    .activateHere{
        margin-top: 110px;
    }
}

.formContainer{
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

.special {
    margin-top: 2rem;
    font-size: 2rem;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
}

.wrongPopUp{
    position: relative;
    width: 92vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: -350px;
    height: 80vh;  
    background: #1C1C1C;
    border-radius: 14px;
    z-index: 10;
}

@media only screen and (min-height: 1180px) {
    .wrongPopUp{
        margin-top: -450px;
    }
}
@media only screen and (min-height: 1080px) {
    .wrongPopUp{
        width: 50vw;
        margin-top: -450px;
    }
}

.popUpTitle{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    letter-spacing: -0.04em;  
    color: #000000;
    position: relative;
    top: 30px;
}
.popUpText{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 105.3%;
    /* or 25px */
    
    text-align: center;
    letter-spacing: -0.04em;
    
    color: #000000;
    position: relative;
    top: 250px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}
.tryAgainButton{
    position: absolute;
    width: 143px;
    height: 46px;
    bottom: -52px;
    left: calc(50% - 143px/2);
    top: calc(50% - 46px/2 + 269px);
    box-shadow:none ;
    background: #000000;
    border-radius: 9px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px; 
    align-items: center;
    text-align: center;
    letter-spacing: -0.04em;
    
    color: #000000;  
}
.popUpButton{
    position: relative;
    align-items: center;
    text-align: center;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: #000000;
    text-decoration: underline;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.04em;
}

.backButton{
    position: absolute;
    align-items: center;
    text-align: center;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    left:20px;
    top:20px;
}

.backButtonImg{
    width: 30.68px;
    height: 30.68px;
}

.errorMessage {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 134.3%;
    /* or 19px */
    
    letter-spacing: -0.04em;
    color: rgb(249, 26, 26);
    padding-top: 9px;
}

#signupButton .spinner {
    display: none;
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    border: 0.2em solid #fff;
    border-top-color: #2196f3;
    animation: spin 1s ease-in-out infinite;
    position: absolute;
    top: calc(50% + 40px);
    left: calc(50%);
    z-index: 7;
}
  
#signupButton.loading .spinner {
    display: block;
}
  
#signupButton.success {
    background-color: #434343;
}
  
#signupButton.success:hover {
    background-color: #434343;
}
  
@keyframes spin {
    to {
      transform: rotate(360deg);
    }
}

.forgotPasswordForm{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscriptionsScreen{
    display: block;
}

/*.menuScreen{
    display: none;
}*/

.wrongPopUp{
    display: none;
}

/* Dimitris below this point */

.store-button {
    height: 50px;
}

a, a:visited{
     color: rgb(0, 0, 0);
}

.videoContainer{
    padding-top: 20px;
    width: 100%;
}
.activeSubVideo{
    width: 100%;
}

.activeSubImage{
    width:200px;
}
.activeSubImages{
    padding-bottom: 10px;
}

@media only screen and (max-width: 400px) {
    .subInfo{
        padding-bottom: 5px;
    }
    .activeSubImage{
        width: 125px;
    }
    .activeSubImages{
        padding-bottom: 5px;
    }
}

.expiredSection{
    padding-top:15px;
}

.rowContainer{
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.formAndErrorContainer{
    height: 53px;
}

.social-buttons {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
}

/* Konstantinos below this point */
/* Share */

.deleteAccountListContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.deleteAccountStep {
    text-align: left;
    margin-bottom: 2px;
}

.shareContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding-bottom: 28px;
    margin-top: 65px;
    margin-bottom: 65px;
}

.thumbnailContainer {
    z-index: inherit;
    width: 32%;
    position: relative;
}

.thumbnailImage {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 3px;
    object-fit: cover;
    background-color: #F7F7F7AA;
}

.bottomLeftDetailsContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 16px;
}

.insideCardDetailsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    margin-top: 12px;
    margin-bottom: 12px;
    height: 0;
    opacity: 0;
}

.detailsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
}

.smallBlackDot {
    width: 5px;
    height: 5px;
    border-radius: 100px;
    background-color: black;
}

.detailsText {
    font-family: Haas Grot Disp Roman;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
}

.subDetailsText {
    font-family: Art Company Mono;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    text-align: left;
    text-transform: uppercase;
}

.shareHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 32px;
    margin-top: 50px;
    margin-bottom: 34px;
}

.getToKnowUsContainer {
    border: 2px solid black;
    border-radius: 100px;
    padding-top: 4px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.getToKnowUsLink {
    text-decoration: none;
}

.getToKnowUs {
    font-family: Haas Grot Disp Roman;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: center;
}

.othersImgShare {
    width: 178px;
}

.othersImgBackground {
    display: flex;
    position: absolute;
    top: 25%;
    left: -25%;
    z-index: -1;
    width: 50%;
}

.card {
    position: relative;
    background-color: #FFFFFF66;
    display: flex;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-right: 44px;
    padding-left: 44px;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 20px;
    z-index: inherit;
    width: 30vw;
    min-height: 32vw;
    border: 1px solid #F7F7F7E5;
    border-left: 1px solid #f9f9f92c;
    border-radius: 8px;
    border-top-right-radius: 0px;
    box-shadow: 1px 3px 10px 0px #00000040;
    transform: translateX(-16px);
}

.cardTop {
    display: flex;
    flex-direction: column;
}

.cardBottom {
    display: flex;
    flex-direction: column;
}

.shareTitle {
    font-family: Haas Grot Disp Roman;
    font-size: 48px;
    font-weight: 500;
    line-height: 50px;
    text-align: left;
    margin-bottom: 14px;
}

.artistContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 14px;
    margin-bottom: 10px;
}

.shareArtist {
    font-family: Haas Grot Disp Medium;
    font-size: 34px;
    font-weight: 400;
    line-height: 32px;
    padding-top: 6px;
}

.artistThumbnailImage {
    width: 40px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 100px;
    background-color: #F7F7F7AA;
}

.shareDescription {
    display: flex;
    text-align: left;
    font-family: Haas Grot Disp Medium;
    font-size: 24px;
    font-weight: 300;
    line-height: 27px;
    margin-top: 20px;
}

.topRightTagContainer {
    position: absolute;
    top: -52px;
    right: 0px;
    height: 52px;
    background: #020202;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mediaType {
    margin-left: 25px;
    font-family: Haas Grot Disp Medium;
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    color: #F7F7F7;
}

.tagContainer {
    padding-left: 27px;
    padding-right: 27px;
    margin-left: 27px;
    border-left: 2px solid #F7F7F7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    height: 27px;
}

.tagIcon {
    width: 17px;
    height: 17px;
    margin-bottom: 1px;
}

.bigTagIcon {
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
}

.tag {
    display: flex;
    text-align: left;
    font-family: Haas Grot Disp Roman;
    font-size: 17px;
    font-weight: 500;
    line-height: 17px;
    color: #F7F7F7;
}

.ctaShareText {
    text-align: left;
    margin-top: 27;
    font-family: Haas Grot Disp Roman;
    font-size: 36px;
    font-weight: 500;
    line-height: 40px;
}

.badgesContainer {
    margin-top: 27px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 27px;
}

.badge {
    height: 65px;
}

.freeTrialText {
    font-family: Haas Grot Disp Roman;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;

}

@media only screen and (max-width: 1440px) {
    .getToKnowUs {
        font-size: 16px;
        line-height: 18px;
    }

    .othersImgShare {
        width: 120px;
    }

    .detailsText {
        font-family: Haas Grot Disp Roman;
        font-size: 10px;
        font-weight: 500;
        line-height: 10px;
        text-align: left;
    }

    .subDetailsText {
        font-size: 8px;
        line-height: 10px;
    }

    .shareTitle {
        font-size: 32px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .artistContainer {
        column-gap: 6px;
        margin-bottom: 6px;
    }

    .shareArtist {
        font-size: 20px;
        line-height: 23px;
        padding-top: 2px;
    }

    .artistThumbnailImage {
        width: 25px;
    }

    .shareDescription {
        font-size: 18px;
        line-height: 20px;
        margin-top: 10px;
    }

    .topRightTagContainer {
        top: -40px;
        height: 40px;
    }

    .mediaType {
        margin-left: 16px;
        font-size: 18px;
        line-height: 20px;
    }

    .tagContainer {
        padding-left: 16px;
        padding-right: 16px;
        margin-left: 16px;
        column-gap: 3px;
        height: 16px;
    }

    .tagIcon {
        width: 12px;
        height: 12px;
    }

    .bigTagIcon {
        width: 10px;
        height: 10px;
    }

    .tag {
        font-size: 12px;
        line-height: 12px;
    }

    .ctaShareText {
        margin-top: 18px;
        font-size: 24px;
        line-height: 30px;
    }

    .badgesContainer {
        margin-top: 12px;
    }

    .badge {
        height: 45px;
    }

    .freeTrialText {
        font-size: 14px;
        line-height: 35px;
    }
}

@media only screen and (max-width: 1024px) {
    .card {
        width: 35vw;
    }

    .badge {
        height: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .shareContainer {
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .thumbnailContainer {
        width: 85%;
    }

    .bottomLeftDetailsContainer {
        opacity: 0;
    }

    .insideCardDetailsContainer {
        opacity: 1;
        height: auto;
    }

    .detailsRow {
        column-gap: 3px;
    }

    .smallBlackDot {
        width: 3px;
        height: 3px;
    }

    .detailsText {
        font-size: 10px;
        line-height: 10px;
    }

    .subDetailsText {
        font-size: 8px;
        line-height: 10px;
    }

    .shareHeader {
        margin-top: 16px;
        margin-bottom: 20px;
        column-gap: 10px;
    }

    .getToKnowUs {
        font-size: 10px;
        line-height: 12px;
    }

    .othersImgShare {
        width: 76px;
    }

    .othersImgBackground {
        opacity: 0;
    }

    .card {
        padding-top: 20px;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
        margin-left: 16px;
        margin-right: 16px;
        width: 87%;
        min-width: 87%;
        border-left: 1px solid #F7F7F7E5;
        border-top: 1px solid #f9f9f92c;
        border-top-right-radius: 8px;
        transform: translateX(0px);
        transform: translateY(-16px);
    }

    .shareTitle {
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 7px;
    }

    .artistContainer {
        column-gap: 6px;
        margin-bottom: 6px;
    }

    .shareArtist {
        font-size: 14px;
        line-height: 17px;
        padding-top: 2px;
    }

    .artistThumbnailImage {
        width: 16px;
    }

    .shareDescription {
        font-size: 14px;
        line-height: 16px;
        margin-top: 10px;
    }

    .topRightTagContainer {
        top: -22px;
        height: 22px;
    }

    .mediaType {
        margin-left: 12px;
        font-size: 12px;
        line-height: 14px;
    }

    .tagContainer {
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 10px;
        column-gap: 3px;
        height: 11px;
    }

    .tagIcon {
        width: 5px;
        height: 5px;
    }

    .bigTagIcon {
        width: 8px;
        height: 8px;
        margin-bottom: 1px;
    }

    .tag {
        font-size: 5px;
        line-height: 5px;
    }

    .ctaShareText {
        margin-top: 18px;
        font-size: 17px;
        line-height: 21px;
    }

    .badgesContainer {
        margin-top: 12px;
        column-gap: 16px;
    }

    .badge {
        height: 40px;
    }

    .freeTrialText {
        font-size: 10px;
        line-height: 25px;
    }

}